export const log = (...args) => {
  args.forEach(arg => console.dir(arg));
  console.trace()
}

export const warn = (...args) => {
  args.forEach(arg => console.warn(arg));
  console.trace();
}

export const wait = (time = 1E3) => new Promise(r => setTimeout(r, time));

import {Notyf} from 'notyf';
import 'notyf/notyf.min.css';

const FIVE_SECONDS = 5E3;

export default new Notyf({
  position: {
    x: 'right',
    y: 'top',
  },
  ripple: true,
  dismissible: true,
  duration: FIVE_SECONDS,
});
